import _axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'querystring';

import { API_BASE_URL } from './config';

export const axios = _axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  paramsSerializer(params: any) {
    return qs.stringify(params);
  },
});

export const axiosBlob = _axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  responseType: 'blob',
  paramsSerializer(params: any) {
    return qs.stringify(params);
  },
});

export async function apiGet<T>(url: string): Promise<AxiosResponse<T>> {
  return axios.get<T>(url);
}

export async function apiPostBlob<T>(url: string, body: any): Promise<AxiosResponse<T>> {
  return axiosBlob.post<T>(url, body);
}

export async function apiGetWithParams<T, K>(url: string, params: K): Promise<AxiosResponse<T>> {
  return axios.get<T>(url, { params });
}

export async function apiPut<T>(url: string, payload: any): Promise<AxiosResponse<T>> {
  return axios.put<T>(url, payload);
}

//methods to replace
export async function apiPost<T>(url: string, payload?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  return axios.post<T>(url, payload, config);
}

export async function apiDelete<T>(url: string): Promise<AxiosResponse<T>> {
  return axios.delete<T>(url);
}
