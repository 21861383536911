import { apiPostBlob } from 'api';
import { isEqual } from 'lodash';
import reject from 'lodash/reject';
import { useSnackbar } from 'notistack';
import React, { FC, Reducer, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import { Box, Button, Grid, Link, MenuItem, TextField, Typography, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { useCustomFonts, useDocumentStyle, useDocumentStyleList } from '../../../hooks/useDocumentStyle';
import { BusyButton } from '../../BusyButton/BusyButton';
import { PdfDocument } from '../PdfDocument';
import { fontsToFontFaceCss } from '../utils/fontsToFontFaceCss';
import AddAttributeSection from './AddAttributeSection/AddAttributeSection';
import AttributesSection from './AttributesSection/AttributesSection';
import { Editor } from './Editor';
import { API_BASE_URL } from 'api/config';
import { useOrganization } from 'contexts/organization';
import { ReactComponent as Download } from 'images/download_rounded.svg';
import { ReactComponent as ExportPDF } from 'images/export_pdf.svg';
import { ReactComponent as Pagination } from 'images/pagination.svg';
import { ReactComponent as ViewAll } from 'images/view_all.svg';
import { DocCategory } from 'models/DocCategory';
import { BaseDocument } from 'models/DocumentTemplate';
import { DocumentCommentThread } from 'models/documentation/Documentation';
import { DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';
import { getDocumentAttributeId } from 'shared/helpers/attributes';
import { updateAttributes as updateExistingAttributes } from 'shared/helpers/document';
import { fetchDocumentPdf } from 'shared/helpers/document/fetchPdf';
import { getCompatibleCategories, isPDFCategory } from 'shared/helpers/document/isPdf';
import { getDocumentStyleCss } from 'shared/helpers/documentStyle';
import { downloadBlob } from 'shared/helpers/download';
import { getDocCategoryTK } from 'shared/helpers/translationKeys';
import { useAttributeCompanyAutocomplete } from 'shared/hooks/useAttributeCompanyAutocomplete';
import { useDefaultDocumentAttributes } from 'shared/hooks/useDefaultAttributes';
import { useAdditionalNavbar } from 'shared/layouts/AdditionalNavbarProvider';

interface DocumentEditorProps {
  editableStyle?: boolean;
  document: BaseDocument;
  onConfirm: (document: BaseDocument) => void;
  enableTrackChangesInToolbar?: boolean;
  canEditAttribute: boolean;
  canAddAttribute: boolean;
  canRemoveAttribute: boolean;
  editableContent?: boolean;
  displayAttributeValues: boolean;
  reloadDocument?: (d: BaseDocument) => void;
  commentsEnabled?: boolean;
  documentHeader?: React.ReactNode;
  pdfFile: string;
  leftColumnHeader?: React.ReactNode;
  additionalButtons?: React.ReactNode;
  loading?: boolean;
}

enum DraftActionType {
  UPDATE_FIELD,
  ADD_ATTRIBUTE,
  RESET,
  REMOVE_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  UPDATE_COMMENT_THREADS,
  UPDATE_ATTRIBUTES,
}

interface Action {
  type: DraftActionType;
  payload: any;
}

const StyleDropdown: FC<{ disabled: boolean; value?: string; onChange: (styleId: string) => void }> = ({
  onChange,
  value,
  disabled,
}) => {
  const { organizationId, membershipId } = useOrganization();
  const styles = useDocumentStyleList(organizationId, membershipId);

  const { t } = useTranslation();
  return (
    <Box p={2}>
      <TextField
        select
        value={value || ''}
        fullWidth
        variant="outlined"
        label={t('document_editor.style_label', 'Style')}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      >
        <MenuItem value="">{t('document_editor.style_none_label', 'None')}</MenuItem>
        {styles.map((s) => (
          <MenuItem key={s.id} value={s.id}>
            {s.name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

const draftReducer: Reducer<DocumentDraft, Action> = (state, action) => {
  switch (action.type) {
    case DraftActionType.RESET:
      return { ...action.payload };
    case DraftActionType.UPDATE_FIELD:
      return { ...state, [action.payload.field]: action.payload.data };
    case DraftActionType.ADD_ATTRIBUTE:
      return { ...state, attributes: state.attributes.concat(action.payload) };
    case DraftActionType.UPDATE_COMMENT_THREADS:
      return { ...state, commentThreads: action.payload };
    case DraftActionType.REMOVE_ATTRIBUTE:
      return { ...state, attributes: reject(state.attributes, action.payload) };
    case DraftActionType.UPDATE_ATTRIBUTE:
      const clone = [...state.attributes];
      const index = clone.findIndex((a) => getDocumentAttributeId(a) === getDocumentAttributeId(action.payload));
      clone.splice(index, 1, action.payload);

      return {
        ...state,
        attributes: clone,
      };
    case DraftActionType.UPDATE_ATTRIBUTES:
      return {
        ...state,
        attributes: updateExistingAttributes(state.attributes, action.payload),
      };
    default:
      return state;
  }
};
type DocumentDraft = Omit<BaseDocument, 'content'>;
const prepareInitialState = ({ name, attributes, category, styleId }: BaseDocument): DocumentDraft => ({
  name,
  attributes,
  category,
  styleId,
});

const useDraftReducer = (initial: any) => {
  const [draft, dispatch] = useReducer(draftReducer, initial, prepareInitialState);

  return {
    draft,
    setName: useCallback(
      (name: string) => dispatch({ type: DraftActionType.UPDATE_FIELD, payload: { field: 'name', data: name } }),
      []
    ),
    setCategory: useCallback(
      (category: string) =>
        dispatch({ type: DraftActionType.UPDATE_FIELD, payload: { field: 'category', data: category } }),
      []
    ),
    setStyleId: useCallback(
      (styleId: string) =>
        dispatch({ type: DraftActionType.UPDATE_FIELD, payload: { field: 'styleId', data: styleId } }),
      []
    ),

    setAttributes: useCallback(
      (attributes: any[]) =>
        dispatch({ type: DraftActionType.UPDATE_FIELD, payload: { field: 'attributes', data: attributes } }),
      []
    ),
    updateAttribute: useCallback(
      (attribute: DocumentAttribute) => dispatch({ type: DraftActionType.UPDATE_ATTRIBUTE, payload: attribute }),
      []
    ),
    updateCommentThreads: useCallback(
      (threads: DocumentCommentThread[]) =>
        dispatch({ type: DraftActionType.UPDATE_COMMENT_THREADS, payload: threads }),
      []
    ),
    updateAttributes: useCallback(
      (attributes: DocumentAttribute[]) => dispatch({ type: DraftActionType.UPDATE_ATTRIBUTES, payload: attributes }),
      []
    ),
    addAttribute: useCallback(
      (attribute: any) => dispatch({ type: DraftActionType.ADD_ATTRIBUTE, payload: attribute }),
      []
    ),
    removeAttribute: useCallback((id: any) => dispatch({ type: DraftActionType.REMOVE_ATTRIBUTE, payload: id }), []),
    reset: useCallback(
      (data: any) => dispatch({ type: DraftActionType.RESET, payload: prepareInitialState(data) }),
      []
    ),
  };
};

const useStyles = makeStyles(() => ({
  leftColumn: {
    height: '100%',
    overflowY: 'auto',
    flexGrow: 0,
    flexShrink: 0,
    maxWidth: 400,
    minWidth: 340,
    flexBasis: '20%',
  },
  rightColumn: {
    height: '100%',
    minWidth: 1000,
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '50%',
  },
  container: {
    height: `calc(100vh - 160px)`,
    overflow: 'auto',
  },
  bottomBar: {
    bottom: 0,
    top: 'auto',
    width: 'auto',
    right: 0,
  },
}));

export const DocumentEditor: FC<DocumentEditorProps> = (props) => {
  const {
    document,
    onConfirm,
    editableStyle,
    children,
    displayAttributeValues,
    reloadDocument,
    editableContent = true,
    commentsEnabled = false,
    documentHeader,
    pdfFile,
    leftColumnHeader,
    additionalButtons,
    loading = false,
    canEditAttribute,
    enableTrackChangesInToolbar,
  } = props;

  const { t } = useTranslation();
  const {
    params: { organizationId, membershipId },
  } = useRouteMatch<{ organizationId: string; membershipId: string }>();

  const styles = useStyles();
  const [defaultAttributes] = useDefaultDocumentAttributes(organizationId, membershipId);
  const snackbar = useSnackbar();

  const [exportLoading, setExportLoading] = useState(false);

  const isPDF = isPDFCategory(document.category);
  const {
    draft,
    setName,
    updateAttribute,
    updateAttributes,
    addAttribute,
    reset,
    setStyleId,
    removeAttribute,
    setCategory,
  } = useDraftReducer(document);

  const [contentChanged, setContentChanged] = useState(false);
  const ignoreChanges = useRef(false);
  const onEditorChange = useCallback(() => {
    if (!ignoreChanges.current) {
      setContentChanged(true);
    }
  }, []);
  const isChanged = useMemo(
    () =>
      contentChanged ||
      !isEqual(draft, {
        name: document.name,
        attributes: document.attributes,
        category: document.category,
        styleId: document.styleId,
      }),
    [contentChanged, draft, document]
  );

  const editorRef = useRef<any>();
  const discardEditorContent = (data: string) => {
    ignoreChanges.current = true;
    setContentChanged(false);
    editorRef.current.setData(data);
    ignoreChanges.current = false;
  };

  const { content } = document;
  useEffect(() => {
    if (isPDF || !editorRef.current || !content) {
      return;
    }

    discardEditorContent(content);
  }, [isPDF, content]);

  const discardChanges = useCallback(() => {
    reset(document);

    if (isPDF || !editorRef.current || !document) {
      return;
    }
    discardEditorContent(document.content);
  }, [isPDF, reset, document]);

  useEffect(() => {
    if (!contentChanged) {
      ignoreChanges.current = false;
    }
  }, [contentChanged]);

  const onConfirmClick = useCallback(() => {
    const data: BaseDocument = { ...draft } as BaseDocument;

    if (!isPDF) {
      data.content = editorRef.current.getData({ useAttributeValue: false });
      const currentSugestions = editorRef.current.getSuggestions();
      if (currentSugestions?.length > 0) {
        data.suggestions = currentSugestions;
      }
    }
    onConfirm(data);
    setContentChanged(false);
  }, [draft, isPDF, onConfirm]);

  const documentStyle = useDocumentStyle(organizationId, membershipId, draft.styleId);
  const customFonts = useCustomFonts(organizationId, membershipId, documentStyle?.customFonts);

  const fontsCss = fontsToFontFaceCss(customFonts);

  const exportToPdf = useCallback(() => {
    const e = editorRef.current;
    if (!e || !documentStyle || !customFonts) {
      return;
    }
    const fixReadOnly = e.isReadOnly;

    if (fixReadOnly) {
      e.isReadOnly = false;
    }

    // setExportLoading(true);

    console.log('TESTTESTTEST');
    console.log(document);
    console.log(documentStyle);
    console.log(customFonts);
    console.log(displayAttributeValues);

    fetchDocumentPdf(document, documentStyle, customFonts, displayAttributeValues)
      .then((b) => downloadBlob(new Blob([b]), `${document.name}.pdf`))
      .catch((e) => {
        snackbar.enqueueSnackbar(t('common.error.unknown') + e.message, { variant: 'error' });
      })
      .finally(() => {
        setExportLoading(false);
      });

    if (fixReadOnly) {
      e.isReadOnly = true;
    }
  }, [customFonts, displayAttributeValues, document, documentStyle, snackbar, t]);

  const editorReloadDocument = useCallback(() => {
    if (isPDF || !editorRef.current) {
      return;
    }

    return reloadDocument?.({
      ...document,
      content: editorRef.current.getData({ useAttributeValue: false }),
    });
  }, [isPDF, reloadDocument, document]);

  useAttributeCompanyAutocomplete(draft.attributes, updateAttributes);
  const { category: currCategory } = document;
  const categories = useMemo(() => {
    const arr = getCompatibleCategories(currCategory);
    if (currCategory && !arr.includes(currCategory)) {
      arr.push(currCategory);
    }
    return arr;
  }, [currCategory]);

  const customCss = useMemo(() => {
    if (!documentStyle) {
      return '';
    }
    return [getDocumentStyleCss(documentStyle), fontsCss].join(' ');
  }, [documentStyle, fontsCss]);

  const [numPDFPages, setNumPDFPages] = useState(0);
  const [showAllPDFPages, setShowAllPDFPages] = useState(false);

  const canSave = editableContent || canEditAttribute;

  const { generateNavbarChildren, breadcrumbs, setCurrentDocumentName } = useAdditionalNavbar();

  React.useEffect(() => setCurrentDocumentName(document.name), [document, setCurrentDocumentName]);

  const {
    params: { documentationId, documentId },
  } = useRouteMatch<{ documentationId: string; documentId: string; organizationId: string; membershipId: string }>();

  const handleDownload = useCallback(async () => {
    const generatePdfUrl =
      `${API_BASE_URL}/legal-agreement/${organizationId}/${membershipId}` +
      `/${documentationId}/${documentId}/generate-pdf`;

    const response = await apiPostBlob(generatePdfUrl, {
      ...documentStyle,
    });
    try {
      const blob = new Blob([response.data as BlobPart], { type: 'application/pdf' });
      const link = window.document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `legal_agreement_${documentationId}_${documentId}.pdf`;
      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const additionalNavbar = useMemo(
    () => (
      <Box
        paddingLeft={5}
        paddingRight={3.5}
        display="flex"
        alignItems="center"
        style={{ backgroundColor: '#FFF', height: '60px', border: '1px solid #EEECE8', boxSizing: 'border-box' }}
      >
        {/* <AppBar position="fixed" color="transparent" elevation={0} className={styles.bottomBar}> */}
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {breadcrumbs}
          </Box>
          <Box display="flex" alignItems="center">
            <Grid container spacing={2} justify="flex-end">
              {numPDFPages > 1 ? (
                // <Box my={2}>
                <Grid item>
                  <Button
                    startIcon={showAllPDFPages ? <Pagination /> : <ViewAll />}
                    onClick={() => setShowAllPDFPages((show) => !show)}
                  >
                    {showAllPDFPages
                      ? t('pdf_document.display_pagination', 'Display pagination')
                      : t('pdf_document.display_all_pages', 'Display all pages')}
                  </Button>
                </Grid>
              ) : // </Box>
              null}
              <Grid item>
                {isPDF ? (
                  <Link href={pdfFile} download={document.name}>
                    <Button startIcon={<Download />}>
                      {t('document_editor.download_pdf_document', 'Download PDF')}
                    </Button>
                  </Link>
                ) : (
                  <Box>
                    <BusyButton
                      disabled={!documentStyle}
                      startIcon={<ExportPDF />}
                      onClick={exportToPdf}
                      busy={exportLoading}
                    >
                      {t('document_editor.export_to_pdf', 'Export to PDF')}
                    </BusyButton>
                    <BusyButton
                      disabled={!documentStyle}
                      startIcon={<ExportPDF />}
                      onClick={async () => await handleDownload()}
                      busy={exportLoading}
                    >
                      {t('document_editor.export_to_pdf_custom', 'Eksport do PDF(PACTT)')}
                    </BusyButton>
                  </Box>
                )}
              </Grid>
              <Grid item>
                {isChanged && canSave ? (
                  <Button startIcon={<Close />} onClick={discardChanges}>
                    {t('document_editor.discard_changes', 'Discard changes')}
                  </Button>
                ) : null}
              </Grid>
              {children}
              <Grid item>
                <BusyButton
                  busy={loading}
                  color="primary"
                  variant="contained"
                  disabled={!isChanged || !canSave}
                  onClick={onConfirmClick}
                >
                  {t('document_editor.save', 'Save Document')}
                </BusyButton>
              </Grid>
              {additionalButtons}
            </Grid>
          </Box>
        </Box>
        {/* </AppBar> */}
      </Box>
    ),
    [
      additionalButtons,
      breadcrumbs,
      canSave,
      children,
      discardChanges,
      document.name,
      documentStyle,
      exportLoading,
      exportToPdf,
      isChanged,
      isPDF,
      loading,
      onConfirmClick,
      pdfFile,
      numPDFPages,
      showAllPDFPages,
      t,
    ]
  );

  useEffect(() => {
    generateNavbarChildren(additionalNavbar);
    return () => generateNavbarChildren(<></>);
    // with empty dependency array, this useEffect fire once on every render;
    // with no dependency array or with additionalNavbar or/and generateNavbarChildren in array, useEffect fires in endless loop
    // but, because eslint warns about error, whe should use the following command
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    additionalButtons,
    canSave,
    children,
    discardChanges,
    document.name,
    documentStyle,
    exportLoading,
    exportToPdf,
    isChanged,
    isPDF,
    loading,
    onConfirmClick,
    pdfFile,
    numPDFPages,
    showAllPDFPages,
  ]);

  return (
    <>
      <style>{customCss}</style>
      <Grid container wrap={'nowrap'} spacing={0} className={styles.container}>
        <Grid item className={styles.leftColumn}>
          {leftColumnHeader ? <Box p={2}>{leftColumnHeader}</Box> : null}
          <Box p={2}>
            <TextField
              value={draft.name}
              fullWidth
              variant="outlined"
              label={t('document_editor.name_label', 'Name')}
              onChange={(e) => setName(e.target.value)}
              disabled={!editableContent}
            />
          </Box>
          <Box p={2}>
            <TextField
              select
              value={draft.category}
              fullWidth
              variant="outlined"
              label={t('document_editor.category_label', 'Category')}
              onChange={(e) => setCategory(e.target.value)}
              disabled={!editableContent}
            >
              {categories.map((v) => (
                <MenuItem key={v} value={v}>
                  {t(getDocCategoryTK(v as DocCategory))}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box p={2}>
            <Typography variant="h4">{t('document_editor.attributes_header', 'Attributes')}</Typography>

            {props.canAddAttribute ? (
              <AddAttributeSection
                defaultAttributes={defaultAttributes!}
                attributes={draft.attributes}
                handleAddAttributes={(arg) => {
                  addAttribute(arg);
                }}
              />
            ) : null}
            <AttributesSection
              attributes={draft.attributes}
              attrValuesDisabled={!props.canEditAttribute}
              handleRemoveAttribute={removeAttribute}
              removable={editableContent}
              updateAttribute={updateAttribute}
            />
          </Box>
        </Grid>
        <Grid item className={styles.rightColumn}>
          {documentHeader}
          <Box mb={2}>
            {isPDF ? (
              <PdfDocument
                numPages={numPDFPages}
                showAll={showAllPDFPages}
                setNumPages={setNumPDFPages}
                file={pdfFile}
              />
            ) : (
              <Editor
                documentStyle={documentStyle}
                customFonts={customFonts}
                hasTabs={Boolean(documentHeader)}
                commentsEnabled={commentsEnabled}
                reloadDocument={editorReloadDocument}
                readOnly={!editableContent}
                editorRef={editorRef}
                initialData={document.content}
                onChange={onEditorChange}
                initialSuggestions={document.suggestions || []}
                attributes={draft.attributes}
                displayAttributeValues={displayAttributeValues}
                enableTrackChangesInToolbar={enableTrackChangesInToolbar}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
