import { UserType } from 'models/Enums';

const organizationPath = '/organization/:organizationId';
const membershipPath = `${organizationPath}/:membershipId`;

export const Paths = {
  ADMIN_ORGANIZATIONS: '/admin/organizations',
  ADMIN_ORGANIZATION_DETAILS: '/admin/organization/details/:id',
  ADMIN_ORGANIZATION_STYLE_CREATE: '/admin/organization/details/:id/styles/create',
  ADMIN_ORGANIZATION_STYLE_DETAILS: '/admin/organization/details/:id/styles/details/:styleId',
  ADMIN_ORGANIZATION_STYLE_FONT_DETAILS: '/admin/organization/details/:id/fonts/details/:fontId',
  ADMIN_ORGANIZATION_STYLE_FONT_CREATE: '/admin/organization/details/:id/fonts/create',
  ADMIN_ORGANIZATION_UPDATE: '/admin/organization/update/:id',
  ADMIN_ORGANIZATION_CREATE: '/admin/organization/create',
  ADMIN_GLOBAL_CONFIG: '/admin/global-config',
  ADMIN_MASS_ACTION: '/admin/mass-actions',
  ADMIN_MASS_ACTION_BULK_SIGNING: '/admin/mass-actions/bulk-signing',

  ADMIN_USERS: '/admin/users',
  USER_DASHBOARD: '/',
  ORGANIZATION: membershipPath,
  ORGANIZATION_USERTYPE: `${organizationPath}/:userType(${Object.values(UserType).join('|')})`,
  ORGANIZATION_USERS: `${membershipPath}/users`,
  ORGANIZATION_ATTRIBUTES: `${membershipPath}/attributes`,
  MEMBER_SETTINGS: `${membershipPath}/profile`,
  DOCUMENTATION_CREATE: `${membershipPath}/documentation/create`,
  DOCUMENTATION_CREATE_FROM_TEMPLATE: `${membershipPath}/documentation/create/from-template`,
  DOCUMENTATION_CREATE_NEW: `${membershipPath}/documentation/create/new`,
  // eslint-disable-next-line max-len
  DOCUMENTATION_CREATE_AMENDMENT: `${membershipPath}/documentation/:rootDocumentationId/create-amendment/:parentDocumentationId`,
  // eslint-disable-next-line max-len
  DOCUMENTATION_CREATE_AMENDMENT_FROM_TEMPLATE: `${membershipPath}/documentation/:rootDocumentationId/create-amendment/:parentDocumentationId/from-template`,
  // eslint-disable-next-line max-len
  DOCUMENTATION_CREATE_AMENDMENT_NEW: `${membershipPath}/documentation/:rootDocumentationId/create-amendment/:parentDocumentationId/new`,

  MASS_ACTIONS: `${membershipPath}/mass-actions`,
  MASS_ACTIONS_BULK_SIGNING: `${membershipPath}/mass-actions/bulk-signing`,

  DOCUMENTATION_LIST: `${membershipPath}/documentation`,
  DOCUMENTATION_DETAILS: `${membershipPath}/documentation/details/:documentationId`,
  DOCUMENTATION_AMENDMENT_DETAILS: `${membershipPath}/documentation/details/:documentationId/amendment/:amendmentId`,
  DOCUMENTATION_DOCUMENT: `${membershipPath}/documentation/details/:documentationId/document/:documentId`,
  DOCUMENTATION_TEMPLATE_EDIT: `${membershipPath}/documentation-template/edit/:documentationTemplateId`,
  DOCUMENT_TEMPLATES: `${membershipPath}/document-templates`,
  DOCUMENT_TEMPLATE: `${membershipPath}/document-template/:id`,
  AGREEMENT_TEMPLATES: `${membershipPath}/agreement-templates`,

  INVITE_USER_ACCEPT_TOKEN: '/invite/user/accept/:token',
};
